import { NavLink } from "@remix-run/react";
import MenuIcon from "assets/media/icons/MenuIcon.svg";
import InstagramLogo from "assets/media/logos/instagram-opt.svg";
import TiktokLogo from "assets/media/logos/tiktok-opt.svg";
import TheUndergroundLogo from "assets/media/logos/the-underground-opt.svg";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { RemoveScroll } from "react-remove-scroll";
import { ReactSVG } from "react-svg";
import useWindowDimensions from "~/hooks/useWindowDimensions/useWindowDimensions";

import { useAtomValue } from "jotai";
import Cookie from "js-cookie";
import decode from "jwt-decode";
import ReactGA from "react-ga4";
import { INSTAGRAM_PROFILE_URL, TIKTOK_PROFILE_URL } from "~/constants/urls";
import { topBarThemeAtom } from "~/state/jotai";
import { Profile, fetchProfileAtom } from "~/state/jotai/profile.jotai";
import { Button } from "../button";
import { SignIn } from "../sign-in";
import { JwtPayload } from "jsonwebtoken";

export const TopBar: FC<{
	disableSignIn?: boolean;
	className?: string;
}> = ({ disableSignIn = false, className }) => {
	const [showSidebar, setShowSidebar] = useState(false);
	const navRef = useRef<HTMLElement | null>(null);
	const ticketType = "REGULAR";

	// Get profile async not on request
	// This would then be stored in an atom. Maybe even just have the atom deal with it

	const profile = useAtomValue(fetchProfileAtom);

	const [user] = useState<JwtPayload | null>(
		Cookie.get("idToken") ? decode(Cookie.get("idToken") ?? "") : null,
	);
	const profileFromIdToken = useMemo<Partial<Profile> | null>(() => {
		if (user) {
			return {
				email: user.email,
				firstName: user.given_name,
				lastName: user.family_name,
				id: user.sub,
			};
		}
		return null;
	}, [user]);

	const windowDims = useWindowDimensions();

	const theme = useAtomValue(topBarThemeAtom);
	// console.log({ ticketType });

	useEffect(() => {
		if (showSidebar && (windowDims.width ?? 0) > 640) {
			setShowSidebar(false);
		}
	}, [showSidebar, windowDims]);

	const toggleSidebar = useCallback((state?: boolean) => {
		// Scroll to top on toggle
		if (typeof state == "boolean") {
			setShowSidebar(state);
			return;
		}
		navRef.current?.scrollIntoView();
		setShowSidebar((val) => !val);
	}, []);

	const TitleBar = useCallback(() => {
		return (
			<>
				<NavLink
					to={"/"}
					aria-label="home"
					onClick={() => toggleSidebar(false)}
				>
					<ReactSVG
						renumerateIRIElements={false}
						wrapper="svg"
						src={TheUndergroundLogo}
						className={`w-40 h-11 my-1 transition-all ease-in-out duration-200 ${theme.fill}`}
					/>
					{/* <span>The</span>
						<br />
						<span className="ml-1">Underground.</span> */}
				</NavLink>
				<button
					onClick={toggleSidebar}
					type="button"
					className="sm:hidden"
					aria-label="menu"
				>
					<ReactSVG
						renumerateIRIElements={false}
						wrapper="svg"
						src={MenuIcon}
						className={`h-8 w-8 transition-all ease-in-out duration-200 ${theme.fill}`}
					/>
					{/* <HiMenu className="h-8 w-8 " /> */}
				</button>
			</>
		);
	}, [theme]);

	return (
		<div
			className={`flex w-full py-6 pl-4 pr-5 items-center justify-between z-10
			transition-all ease-in-out duration-200 delay-[50ms]
			${className ?? ""} ${theme.background} ${theme.text}`}
		>
			{!showSidebar && <TitleBar />}
			{
				/* Mobile */
				showSidebar && (
					<RemoveScroll
						style={{ backgroundImage: theme.menuBackgroundImage }}
						className={`sm:hidden absolute z-10 top-0 left-0 h-fill-available min-w-fill-available max-w-[100vw] ${theme.menuBackground}`}
					>
						<div className="flex flex-col h-full w-full pb-8">
							<div className="flex w-full pl-4 pr-5 pt-6 items-center justify-between mb-8">
								<TitleBar />
							</div>
							<nav className="w-screen px-5 mb-5">
								<ul className="flex flex-col gap-4">
									<li>
										<NavLink
											onClick={toggleSidebar}
											to={"/events"}
										>
											<div className="h-6 link">
												Eclipse
											</div>
										</NavLink>
									</li>
									<li>
										<NavLink
											onClick={toggleSidebar}
											to={"/ticket"}
										>
											<div className="h-6 link">
												Ticket
											</div>
										</NavLink>
									</li>
									<li>
										<NavLink
											onClick={toggleSidebar}
											to={"/events"}
										>
											<div className="h-6 link">
												Events
											</div>
										</NavLink>
									</li>
								</ul>
							</nav>
							<hr
								className={`${theme.border} border-1 mx-5 mb-5`}
							/>
							<div className="flex gap-4 mx-5">
								<a
									onClick={() =>
										ReactGA.event({
											category: "User",
											action: "Clicked Instagram Link via Menu",
										})
									}
									href={INSTAGRAM_PROFILE_URL}
									target="_blank"
								>
									<ReactSVG
										renumerateIRIElements={false}
										wrapper="svg"
										src={InstagramLogo}
										className={`h-8 w-8 ${theme.fill} ${theme.stroke} stroke-[2]`}
									/>
								</a>
								<a
									onClick={() =>
										ReactGA.event({
											category: "User",
											action: "Clicked Tiktok Link via Menu",
										})
									}
									href={TIKTOK_PROFILE_URL}
									target="_blank"
								>
									<ReactSVG
										renumerateIRIElements={false}
										wrapper="svg"
										src={TiktokLogo}
										className={`h-8 w-8 ${theme.fill} ${theme.stroke} stroke-[2]`}
									/>
								</a>
							</div>
							{ticketType == "CREW" && (
								<>
									<hr
										className={`${theme.border} border-1 mx-5 my-5`}
									/>
									<nav className="w-screen px-5 mb-5">
										<ul className="flex flex-col gap-4">
											<li>
												<NavLink
													onClick={toggleSidebar}
													to={"/scan"}
												>
													<div className="h-6 link">
														Scan
													</div>
												</NavLink>
											</li>
										</ul>
									</nav>
								</>
							)}
							<div className="mt-auto space-y-4 px-5">
								<Button
									to={"/buy"}
									onClick={toggleSidebar}
									border
									cta
								>
									Get your Ticket!
								</Button>
								<SignIn
									disableSignIn={disableSignIn}
									profile={
										profile.state == "hasData"
											? profile.data
											: profileFromIdToken
									}
									className="!justify-start"
								/>
							</div>
						</div>
					</RemoveScroll>
				)
			}
		</div>
	);
};
